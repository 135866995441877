<template>
  <div class="test-exam">
    <!-- 顶部栏 -->
    <div class="header">
      <p class="title">考试成绩</p>
    </div>

    <van-divider class="divider" />

    <!-- 内容区 -->
    <div class="container">
      <!-- 信息概览卡片 -->
      <div class="summary">
        <p class="title">
          {{ detail.examTitle }}
        </p>

        <div class="detail">
          <van-circle
            class="score-circle"
            v-model="currentPercent"
            :stroke-width="70"
            :color="{ '0%': '#FB7A08', '100%': '#FE6113' }"
          >
            <div class="inner-circle"></div>
            <p class="score-text" slot="default">
              {{ detail.score }}<span class="text">分</span>
            </p>
          </van-circle>

          <div class="statistic">
            <div class="d-flex">
              <p style="display:inline-block;min-width:55px;">
              <img
                class="icon"
                src="~@/assets/image/icon/test/clock.png"
                alt=""
              />
              <label class="label">用时</label>
              </p>
              <span>{{ $utils.formatTime(detail.duration).strMin }}</span>
            </div>

            <van-divider class="divider" />
            <div class="d-flex" style="flex-wrap:nowrap;align-items: flex-start">
              <p style="display:inline-block;min-width:55px;">
                <img
                  class="icon"
                  src="~@/assets/image/icon/test/crown.png"
                  alt=""
                />
                <label class="label">排名</label>
              </p>
              <div style="display:inline-block">
                <span
                  >第<span class="rank-text">{{ detail.ranking }}</span
                  >名</span
                >
                <span style="margin-left: 10px">{{ detail.actual }}人参考</span>
                <span class="ml">{{ detail.total }}人应考</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 只看错题切换 -->
      <!-- <div class="d-flex right only-wrong">
        <label>只看错题：</label>
        <van-switch active-color="#FF5809" v-model="onlyWrongs" />
      </div> -->

      <!-- 类型与题目 -->
      <subject
        :title="'单选题'"
        :questions="detail.singleChoiceQuestions"
        :only-wrongs="onlyWrongs"
      />
      <subject
        :title="'多选题'"
        :questions="detail.multipleChoiceQuestions"
        :only-wrongs="onlyWrongs"
        class="mt15"
      />
      <subject
        :title="'问答题'"
        :questions="detail.essayQuestions"
        :only-wrongs="onlyWrongs"
        class="mt15"
      />

      <!-- 关闭按钮 -->
      <van-button
        class="close-btn"
        type="default"
        color="linear-gradient(90deg, #FB7A08, #FE6113)"
        @click="$utils.goBack"
        >关闭</van-button
      >
    </div>
  </div>
</template>

<script>
import { Circle } from 'vant'
import Subject from './components/Subject.vue'
import { getTestResult } from '@/api/my/test'
export default {
  name: 'TestResult',
  provide() {
    return {
      beforeLength: this.beforeLength,
    }
  },
  components: {
    Subject,
    [Circle.name]: Circle,
  },
  data() {
    return {
      onlyWrongs: false,
      currentPercent: 0,
      detail: {
        score: 0,
        totalScore: 0,
        duration: 0,
        ranking: 0,
        total: 0,
        examTitle: '',
        multipleChoiceQuestions: [],
        singleChoiceQuestions: [],
        essayQuestions: [],
      },
    }
  },
  created() {
    this.init()
    this.getResult()
  },
  methods: {
    init() {
      const { w } = this.$route.query
      if (w) {
        this.onlyWrongs = true
      }
    },
    getResult() {
      // 查询考试结果
      const { id } = this.$route.query
      const userId = this.$store.getters.user.id
      getTestResult(userId, id).then((res) => {
        if (res) {
          const { result } = res
          this.detail = result
          console.log(res)
          const { score, totalScore } = this.detail
          this.currentPercent = parseInt((score / totalScore) * 100)
        }
      })
    },
    beforeLength(query) {
      if (query == 1) {
        return 0
      } else if (query == 2) {
        return this.detail.singleChoiceQuestions.length
      } else if (query == 3) {
        return (
          this.detail.singleChoiceQuestions.length +
          this.detail.multipleChoiceQuestions.length
        )
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/common.less';
.header {
  padding: 64px 64px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 64px;
  }
}
.divider {
  margin: 0;
}
.container {
  padding: 64px;
  .summary {
    box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
      -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
    border-radius: 32px;
    padding: 80px 48px;
    .title {
      font-size: 54px;
    }
    .detail {
      margin: 80px 0 0;
      display: flex;
      align-items: center;
      .score-circle {
        margin: 0 64px 0 32px;
        position: relative;
        width: 300px;
        height: 300px;
        .inner-circle {
          border: 6px solid @primary-color;
          border-radius: 100%;
          width: 280px;
          height: 280px;
          position: absolute;
          top: 10px;
          left: 10px;
        }
        .score-text {
          color: @primary-color;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 80px;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          width: 220px;
          height: 220px;
          border-radius: 100%;
          background-color: #f4f4f4;
          text-align: center;
          line-height: 220px;

          .text {
            font-size: 32px;
          }
        }
      }
      .statistic {
        font-size: 42px;
        color: @font-gray-1;
        .icon {
          width: 42px;
          height: auto;
        }
        .label {
          margin: 0 40px 0 24px;
        }
        .divider {
          margin: 40px 0;
        }
        .rank-text {
          color: @primary-color;
        }
        .ml {
          margin: 0 0 0 20px;
        }
      }
    }
  }
  .only-wrong {
    margin: 94px 0 56px 0;
    font-size: 54px;
  }

  .mt15 {
    margin-top: 15px;
  }

  .close-btn {
    width: 100%;
    margin: 88px 0 100px;
    border-radius: 16px;
  }
}
</style>
