<template>
  <div
    class="question"
    :class="{ disabled: disabled }"
    @click="goTestByQuestion(index)"
  >
    <img
      :src="data.ansStatus == 1 ? iconRight : iconWrong"
      alt=""
      class="icon"
    />
    <span class="index">{{ index + 1 }}</span>
    <div v-show="onlyWrongs && data.ansStatus != 1" class="wrong-mask">
      {{ index + 1 }}
    </div>
  </div>
</template>

<script>
const iconRight = require('@/assets/image/icon/test/right.png')
const iconWrong = require('@/assets/image/icon/test/wrong.png')
export default {
  name: 'TestQuestion',
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    onlyWrongs: {
      type: Boolean,
      default: false,
    },
    queType: {
      type: String,
      default: '1',
    },
  },
  inject: ['beforeLength'],
  computed: {
    disabled() {
      return this.onlyWrongs && this.data.ansStatus == 1
    },
  },
  data() {
    return {
      iconRight,
      iconWrong,
    }
  },
  methods: {
    goTestByQuestion(index) {
      // 跳转题目
      if (this.disabled) return
      const { examId } = this.data
      this.$router.push(
        `/test/analysis?id=${examId}&index=${this.beforeLength(this.queType) + index + 1}`
      )
    },
  },
}
</script>

<style lang="less" scoped>
.question {
  width: 120px;
  height: 120px;
  line-height: 120px;
  background-color: #efefef;
  margin: 0 120px 48px 0;
  border-radius: 8px;
  text-align: center;
  position: relative;
  overflow: hidden;
  &:nth-child(5n) {
    margin-right: 0;
  }
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
  }
  .index {
    font-size: 48px;
  }
  .wrong-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    background-color: #fd7d7f;
    font-size: 48px;
  }
}
.disabled {
  filter: opacity(0.3);
}
</style>
