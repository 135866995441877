<template>
  <div class="subject">
    <p class="title">{{ title }}</p>
    <div class="questions">
      <question v-for="(q, qi) in questions" :key="qi" :data="q" :index="qi" :only-wrongs="onlyWrongs" :queType="q.queType"/>
    </div>
  </div>
</template>

<script>
import Question from './Question.vue'
export default {
  name: 'TestSubject',
  components: {
    Question
  },
  props: {
    title: {
      type: String,
      required: true
    },
    questions: {
      type: Array,
      required: true
    },
    onlyWrongs: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';
  .title {
    color: @primary-color;
    font-size: 48px;
    margin: 0 0 48px 0;
  }
  .questions {
    display: flex;
    flex-wrap: wrap;
  }
</style>